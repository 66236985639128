import video from '../audio/alkogol18.mp3';
import video2 from '../audio/alkogolAll.mp3';
import video3 from '../audio/narkotiki.mp3';

export const getResultTest1 = (userTest1) => {
    const { q3, q4, q5, q6, q7, q8, sdt, alt, ast, ggt, age } = userTest1;
    const arrQues3_5 = [q3, q4, q5];
    const arrQues3_6 = [q3, q4, q5, q6];
    if (q8 === 'Да') {
        if (Number(sdt) > 1.6)
            if (q7 === 'Да') {
                return 'two';
            } else {
                return video2;
            }
        if (
            (Number(alt) > 45 || Number(ast) > 35 || Number(ggt) > 50) &&
            arrQues3_6.filter((el) => el === 'Да').length > 1
        )
            if (q7 === 'Да') {
                return 'two';
            } else {
                return video2;
            }
    }
    const flag1 =
        arrQues3_6.filter((el) => el === 'Да').length === 1 && q7 !== 'Да';
    const flag2 =
        arrQues3_6.filter((el) => el === 'Да').length > 1 && q7 !== 'Да';
    const flag3 =
        (q7 === 'Да' && arrQues3_6.filter((el) => el === 'Нет').length === 4) ||
        (q7 === 'Да' &&
            arrQues3_5.filter((el) => el === 'Да').length === 1 &&
            q6 !== 'Да');
    const flag4 =
        (q7 === 'Да' &&
            q6 === 'Да' &&
            arrQues3_5.filter((el) => el === 'Да').length === 0) ||
        (q7 === 'Да' && arrQues3_5.filter((el) => el === 'Да').length > 2);

    if (age < 18 && flag1) return video;
    if (flag2) return video2;
    if (flag3) return video3;
    if (flag4) return 'two';
    return 'no_risk';
};
