import React, { useState } from 'react';

export const Analiz = ({ userTest1, setUserTest1 }) => {
    const [sdt, SetSdt] = useState('');
    const [alt, SetAlt] = useState('');
    const [ast, SetAst] = useState('');
    const [ggt, SetGgt] = useState('');
    const hanlerChangeSdt = (event) => {
        SetSdt(event.target.value);
        setUserTest1({ ...userTest1, sdt: event.target.value });
    };
    const hanlerChangeAlt = (event) => {
        SetAlt(event.target.value);
        setUserTest1({ ...userTest1, alt: event.target.value });
    };
    const hanlerChangeAst = (event) => {
        SetAst(event.target.value);
        setUserTest1({ ...userTest1, ast: event.target.value });
    };
    const hanlerChangeGgt = (event) => {
        SetGgt(event.target.value);
        setUserTest1({ ...userTest1, ggt: event.target.value });
    };
    return (
        <>
            <div className="wrap_analiz">
                <h3>Введите показатели биохимического анализа крови: </h3>
                <label className="label_question">Анализ СДТ:</label>
                <input
                    value={sdt}
                    type="number"
                    onChange={hanlerChangeSdt}
                ></input>
                <br></br>
                <label className="label_question">Анализ АЛТ:</label>
                <input
                    value={alt}
                    type="number"
                    onChange={hanlerChangeAlt}
                ></input>
                <br></br>
                <label className="label_question">Анализ АСТ:</label>
                <input
                    value={ast}
                    type="number"
                    onChange={hanlerChangeAst}
                ></input>
                <br></br>
                <label className="label_question">Анализ ГГТ:&nbsp;</label>
                <input
                    value={ggt}
                    type="number"
                    onChange={hanlerChangeGgt}
                ></input>
                <br></br>
            </div>
        </>
    );
};
