export const db = (userTest1) => {
  const formData = new FormData();
  formData.append("gender", userTest1.gender);
  formData.append("age", userTest1.age);
  formData.append("place", userTest1.place);
  formData.append("city", userTest1.city);
  formData.append("q3", userTest1["q3"]);
  formData.append("q4", userTest1["q4"]);
  formData.append("q5", userTest1["q5"]);
  formData.append("q6", userTest1["q6"]);
  formData.append("q7", userTest1["q7"]);
  formData.append("q8", userTest1["q8"]);
  if (userTest1["sdt"]) {
    formData.append("sdt", userTest1["sdt"]);
  } else {
    formData.append("sdt", 0);
  }
  if (userTest1["alt"]) {
    formData.append("alt", userTest1["alt"]);
  } else {
    formData.append("alt", 0);
  }
  if (userTest1["ast"]) {
    formData.append("ast", userTest1["ast"]);
  } else {
    formData.append("ast", 0);
  }
  if (userTest1["ggt"]) {
    formData.append("ggt", userTest1["ggt"]);
  } else {
    formData.append("ggt", 0);
  }
  fetch("https://vm-63.ru/result.php", {
    mode: "no-cors",
    method: "POST",
    body: formData,
  });
};
