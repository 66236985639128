export const db2 = (userTest2) => {
  const formData = new FormData();
  formData.append("q1", userTest2["q1"]);
  formData.append("q2", userTest2["q2"]);
  formData.append("q3", userTest2["q3"]);
  formData.append("q4", userTest2["q4"]);
  formData.append("tel", userTest2["tel"]);

  fetch("https://vm-63.ru/result2.php", {
    mode: "no-cors",
    method: "POST",
    body: formData,
  });
};
