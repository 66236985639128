import { useState } from "react";
import React from "react";
import "./question/question.css";

export const UserPlace = ({ userTest1, setUserTest1 }) => {
  const [checked, setChecked] = useState(null);
  return (
    <div className="test__wrapper">
      <h2 className="question__title">
        Вопрос 4. Пожалуйста, укажите место, где Вы в данный момент отвечаете на
        ответы Программы (нужно выбрать только один вариант ответа. Если ни один
        вариант не подходит, выберите «иное»)
      </h2>
      <br />
      <br />
      <table align="center" width="50%">
        <thead>
          <tr>
            <td>
              <h3>В амбулаторных условиях:</h3>
            </td>
            <td>
              <h3>В условиях стационара:</h3>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <label className="label_question">
                <input
                  className="input_checkbox"
                  type="checkbox"
                  checked={checked === "При оказании амбулаторной помощи"}
                  onChange={() => {
                    setChecked("При оказании амбулаторной помощи");
                    setUserTest1({
                      ...userTest1,
                      place: "При оказании амбулаторной помощи",
                    });
                  }}
                ></input>
                При оказании амбулаторной помощи
              </label>
              <br />
              <label className="label_question">
                <input
                  className="input_checkbox"
                  type="checkbox"
                  checked={checked === "При прохождении диспансеризации"}
                  onChange={() => {
                    setChecked("При прохождении диспансеризации");
                    setUserTest1({
                      ...userTest1,
                      place: "При прохождении диспансеризации",
                    });
                  }}
                ></input>
                При прохождении диспансеризации
              </label>
              <br />
              <label className="label_question">
                <input
                  className="input_checkbox"
                  type="checkbox"
                  checked={checked === "При прохождении медицинского осмотра"}
                  onChange={() => {
                    setChecked("При прохождении медицинского осмотра");
                    setUserTest1({
                      ...userTest1,
                      place: "При прохождении медицинского осмотра",
                    });
                  }}
                ></input>
                При прохождении медицинского осмотра
              </label>
              <br />
              <label className="label_question">
                <input
                  className="input_checkbox"
                  type="checkbox"
                  checked={checked === "Иное 1 (при амб.)"}
                  onChange={() => {
                    setChecked("Иное 1 (при амб.)");
                    setUserTest1({
                      ...userTest1,
                      place: "Иное 1 (при амб.)",
                    });
                  }}
                ></input>
                Иное
              </label>
            </td>
            <td>
              <label className="label_question">
                <input
                  className="input_checkbox"
                  type="checkbox"
                  checked={checked === "В кардиологическом отделении"}
                  onChange={() => {
                    setChecked("В кардиологическом отделении");
                    setUserTest1({
                      ...userTest1,
                      place: "В кардиологическом отделении",
                    });
                  }}
                ></input>
                В кардиологическом отделении
              </label>
              <br />
              <label className="label_question">
                <input
                  className="input_checkbox"
                  type="checkbox"
                  checked={checked === "В гастроэнтерологическом отделении"}
                  onChange={() => {
                    setChecked("В гастроэнтерологическом отделении");
                    setUserTest1({
                      ...userTest1,
                      place: "В гастроэнтерологическом отделении",
                    });
                  }}
                ></input>
                В гастроэнтерологическом отделении
              </label>
              <br />
              <label className="label_question">
                <input
                  className="input_checkbox"
                  type="checkbox"
                  checked={checked === "В неврологическом отделении"}
                  onChange={() => {
                    setChecked("В неврологическом отделении");
                    setUserTest1({
                      ...userTest1,
                      place: "В неврологическом отделении",
                    });
                  }}
                ></input>
                В неврологическом отделении
              </label>
              <br />
              <label className="label_question">
                <input
                  className="input_checkbox"
                  type="checkbox"
                  checked={checked === "Терапевтическом отделении (больнице)"}
                  onChange={() => {
                    setChecked("Терапевтическом отделении (больнице)");
                    setUserTest1({
                      ...userTest1,
                      place: "Терапевтическом отделении (больнице)",
                    });
                  }}
                ></input>
                Терапевтическом отделении (больнице)
              </label>
              <br />
              <label className="label_question">
                <input
                  className="input_checkbox"
                  type="checkbox"
                  checked={checked === "Противотуберкулезном отделении"}
                  onChange={() => {
                    setChecked("Противотуберкулезном отделении");
                    setUserTest1({
                      ...userTest1,
                      place: "Противотуберкулезном отделении",
                    });
                  }}
                ></input>
                Противотуберкулезном отделении
              </label>
              <br />
              <label className="label_question">
                <input
                  className="input_checkbox"
                  type="checkbox"
                  checked={checked === "Инфекционном отделении"}
                  onChange={() => {
                    setChecked("Инфекционном отделении");
                    setUserTest1({
                      ...userTest1,
                      place: "Инфекционном отделении",
                    });
                  }}
                ></input>
                Инфекционном отделении
              </label>
              <br />
              <label className="label_question">
                <input
                  className="input_checkbox"
                  type="checkbox"
                  checked={checked === "Иное 2 (стац)"}
                  onChange={() => {
                    setChecked("Иное 2 (стац)");
                    setUserTest1({
                      ...userTest1,
                      place: "Иное 2 (стац)",
                    });
                  }}
                ></input>
                Иное
              </label>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};
