import React, { useState } from 'react';
import { Telefon } from './Telefon';
import { db2 } from '../../../utils/DB/db2';

export const TestTwo = ({ setStage, userTest2, setUserTest2 }) => {
    const [check1, setCheck1] = useState(null);
    const [check2, setCheck2] = useState(null);
    const [check3, setCheck3] = useState(null);
    const [check4, setCheck4] = useState(null);
    const [visibleTel, setVisibleTel] = useState(false);

    return (
        <div className="test__two">
            <p>
                Мы благодарим Вас за участие в программе, и в завершении просим
                ответить еще на несколько вопросов, для улучшения нашей работы.
            </p>
            <div className="wrap_test2">
                <table>
                    <tr>
                        <td>
                            1. Был ли прослушанный Вами голосовой модуль Вам
                            полезен?{' '}
                        </td>
                        <td>
                            <input
                                className="input_checkbox"
                                type="checkbox"
                                checked={check1 === 'Да'}
                                onChange={() => {
                                    setCheck1('Да');
                                    setUserTest2({
                                        ...userTest2,
                                        q1: 'Да',
                                    });
                                }}
                            ></input>
                            <label className="label_question">Да&nbsp;</label>
                            <input
                                className="input_checkbox"
                                type="checkbox"
                                checked={check1 === 'Нет'}
                                onChange={() => {
                                    setCheck1('Нет');
                                    setUserTest2({
                                        ...userTest2,
                                        q1: 'Нет',
                                    });
                                }}
                            ></input>
                            <label className="label_question">Нет</label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            2. Планируете ли Вы в ближайшее время изменить свое
                            поведение, сократив, или отказавшись от потребления
                            алкоголя?
                        </td>
                        <td>
                            <input
                                className="input_checkbox"
                                type="checkbox"
                                checked={check2 === 'Да'}
                                onChange={() => {
                                    setCheck2('Да');
                                    setUserTest2({
                                        ...userTest2,
                                        q2: 'Да',
                                    });
                                }}
                            ></input>
                            <label className="label_question">Да&nbsp;</label>
                            <input
                                className="input_checkbox"
                                type="checkbox"
                                checked={check2 === 'Нет'}
                                onChange={() => {
                                    setCheck2('Нет');
                                    setUserTest2({
                                        ...userTest2,
                                        q2: 'Нет',
                                    });
                                }}
                            ></input>
                            <label className="label_question">Нет</label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            3. Планируете те ли Вы обратиться к специалисту
                            наркологу для получения услуг по лечению?
                        </td>
                        <td>
                            <input
                                className="input_checkbox"
                                type="checkbox"
                                checked={check3 === 'Да'}
                                onChange={() => {
                                    setCheck3('Да');
                                    setUserTest2({
                                        ...userTest2,
                                        q3: 'Да',
                                    });
                                }}
                            ></input>
                            <label className="label_question">Да&nbsp;</label>
                            <input
                                className="input_checkbox"
                                type="checkbox"
                                checked={check3 === 'Нет'}
                                onChange={() => {
                                    setCheck3('Нет');
                                    setUserTest2({
                                        ...userTest2,
                                        q3: 'Нет',
                                    });
                                }}
                            ></input>
                            <label className="label_question">Нет</label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            4. И в завершении Вашего участия в программе мы
                            просим Вашего разрешения связаться с вами через 3 и
                            6 месяцев и задать вопросы о том, насколько
                            полученная Вами информация помогла Вам
                        </td>
                        <td>
                            <input
                                className="input_checkbox"
                                type="checkbox"
                                checked={check4 === 'Да'}
                                onChange={() => {
                                    setCheck4('Да');
                                    setUserTest2({
                                        ...userTest2,
                                        q4: 'Да',
                                    });
                                    setVisibleTel(true);
                                }}
                            ></input>
                            <label className="label_question">Да&nbsp;</label>
                            <input
                                className="input_checkbox"
                                type="checkbox"
                                checked={check4 === 'Нет'}
                                onChange={() => {
                                    setCheck4('Нет');
                                    setUserTest2({
                                        ...userTest2,
                                        q4: 'Нет',
                                    });
                                    setVisibleTel(false);
                                }}
                            ></input>
                            <label className="label_question">Нет</label>
                        </td>
                    </tr>
                </table>
            </div>
            {visibleTel && (
                <Telefon userTest2={userTest2} setUserTest2={setUserTest2} />
            )}
            <button
                className="content_button"
                onClick={() => {
                    db2(userTest2);
                    setStage(6);
                }}
            >
                Завершить опрос
            </button>
        </div>
    );
};
