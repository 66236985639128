import React from "react";

export const End = ({ setStage, setUserTest1 }) => {
  setTimeout(() => {
    setStage(1);
    setUserTest1({ sdt: "", alt: "", ast: "", ggt: "" });
  }, 6000);
  return (
    <div className="finish">
      <h4>
        В том случае, если Вы проживаете за пределами Самары, Вы можете
        обратиться к врачу психиатру-наркологу Вашего муниципального
        образования.
      </h4>
      <h5>Спасибо, и будьте здоровы!</h5>
    </div>
  );
};
