import React from 'react';
import { useState } from 'react';
import { Header } from './components/header/Header';
import { Footer } from './components/footer/Footer';
import { Content } from './components/Content/Content';

export const App = () => {
    const [userTest1, setUserTest1] = useState({
        sdt: '',
        alt: '',
        ast: '',
        ggt: '',
    });
    const [userTest2, setUserTest2] = useState({
        q1: '',
        q2: '',
        q3: '',
        q4: '',
        tel: '',
    });
    const [stage, setStage] = useState(1);

    const buttonReset = () => {
        setStage(1);
        setUserTest1({ sdt: '', alt: '', ast: '', ggt: '' });
    };

    return (
        <div className="main">
            <Header />
            <Content
                userTest1={userTest1}
                setUserTest1={setUserTest1}
                stage={stage}
                setStage={setStage}
                userTest2={userTest2}
                setUserTest2={setUserTest2}
            />
            <Footer onClick={buttonReset} />
        </div>
    );
};
