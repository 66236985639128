import React, { useState } from 'react';

export const Telefon = ({ setUserTest2, userTest2 }) => {
    const [tel, setTel] = useState('');
    const handlerChangeInput = (e) => {
        setTel(e.target.value);
        setUserTest2({ ...userTest2, tel: tel });
    };
    return (
        <>
            <label>Введите номер телефона : </label>
            <input value={tel} onChange={handlerChangeInput}></input>
        </>
    );
};
