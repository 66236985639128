import React from "react";

export const FirstLoad = ({ onClick }) => {
    return (
        <>
            <h2>Добрый день!</h2>
            <p>
                Просим Вас принять участие в программе снижения потребления
                психоактивных веществ, вызывающих зависимость, реализуемой в
                медицинских организациях Самарской области. <br />
                <br />
                Для этого мы просим Вас ответить на несколько вопросов для
                выявления у Вас факторов рискованного потребления алкоголя и
                наркотиков, после чего, в зависимости от полученных от Вас
                ответов, выслушать рекомендации по снижению потребления
                психоактивных веществ, если риск будет выявлен. <br /> <br />И
                само анкетирование, и участие в программе является анонимным. Мы
                не просим Вас указывать Вашего имени, а только указать Ваш пол и
                возраст. Это поможет нам в дальнейшем улучшить наши
                рекомендации.
            </p>
            <button className="content__button" onClick={onClick}>
                Итак, приступим!
            </button>
        </>
    );
};
