import { FirstLoad } from './test1/FirstLoad';
import { TestFirst } from './test1/TestFirst';
import React from 'react';
import { VideoPlayer } from './audioPlayList/VideoPlayer';
import { GoodFinish } from './good/GoodFinish';
import { TestTwo } from './test2/TestTwo';
import { End } from './end/End';
import './content.css';

export const Content = ({
    userTest1,
    setUserTest1,
    setStage,
    stage,
    setModalOpen,
    userTest2,
    setUserTest2,
}) => {
    const buttonRunClick = () => {
        setStage(2);
    };

    return (
        <div className="content">
            {stage === 1 && <FirstLoad onClick={buttonRunClick} />}
            {stage === 2 && (
                <TestFirst
                    userTest1={userTest1}
                    setUserTest1={setUserTest1}
                    setStage={setStage}
                    setModalOpen={setModalOpen}
                />
            )}

            {stage === 3 && (
                <VideoPlayer userTest1={userTest1} setStage={setStage} />
            )}
            {stage === 4 && (
                <TestTwo
                    setStage={setStage}
                    userTest2={userTest2}
                    setUserTest2={setUserTest2}
                />
            )}
            {stage === 5 && (
                <GoodFinish setStage={setStage} setUserTest1={setUserTest1} />
            )}
            {stage === 6 && (
                <End setStage={setStage} setUserTest1={setUserTest1} />
            )}
        </div>
    );
};
