import { DataUser } from "./DataUser";
import { UserPlace } from "./UserPlace";
import { UserCity } from "./UserCity";
import { db } from "../../../utils/DB/db";
import React from "react";
import { useState } from "react";
import { Question } from "./question/Question";
import { getResultTest1 } from "../../../utils/getResultTest1";

export const TestFirst = ({ userTest1, setUserTest1, setStage }) => {
  const [count, setCount] = useState(0);

  const finishTest1 = () => {
    db(userTest1);
    if (getResultTest1(userTest1) === "no_risk") {
      setStage(5);
    } else {
      setStage(3);
    }
  };
  //   useEffect(() => {
  //     if (document.querySelector(".test1__next")) {
  //       (count !== Object.keys(userTest1).length - 4 || userTest1.age === "") &&
  //       count !== Object.keys(userTest1).length - 6
  //         ? document.querySelector(".test1__next").setAttribute("disabled", "")
  //         : document.querySelector(".test1__next").removeAttribute("disabled");
  //     }
  //   }, [userTest1, count, userTest1.age]);

  return (
    <>
      {count < 9 && count > 2 && (
        <Question
          count={count}
          userTest1={userTest1}
          setUserTest1={setUserTest1}
        />
      )}
      {count === 0 && (
        <DataUser userTest1={userTest1} setUserTest1={setUserTest1} />
      )}
      {count === 1 && (
        <UserCity userTest1={userTest1} setUserTest1={setUserTest1} />
      )}
      {count === 2 && (
        <UserPlace userTest1={userTest1} setUserTest1={setUserTest1} />
      )}

      {count === 9 && (
        <button className="test1__finish" onClick={finishTest1}>
          Завершить опрос
        </button>
      )}
      {count !== 9 && (
        <button
          className="test1__next"
          onClick={() => {
            setCount(count + 1);
          }}
        >
          Следующий вопрос
        </button>
      )}
    </>
  );
};
