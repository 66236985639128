import { useState } from "react";
import React from "react";
import "./question/question.css";

const City = [
  "Сделайте выбор",
  "г.о.Жигулевск",
  "г.о.Похвистнево",
  "г.о.Отрадный",
  "г.о.Новокуйбышевск",
  "г.о.Кинель",
  "г.о.Чапаевск",
  "г.о.Тольятти",
  "г.о.Самара",
  "г.о.Октябрьск",
  "г.о.Сызрань",
];

const City2 = [
  "Сделайте выбор",
  "м.р. Алексеевский",
  "м.р. Безенчукский",
  "м.р.Богатовский",
  "м.р. Большеглушицкий",
  "м.р. Большечерниговский",
  "м.р. Борский",
  "м.р. Волжский",
  "м.р. Елховский",
  "м.р. Исаклинский",
  "м.р. Камышлинский",
  "м.р. Кинельский",
  "м.р. Кинель-Черкасский",
  "м.р. Клявлинский",
  "м.р. Кошкинский",
  "м.р. Красноармейский",
  "м.р. Красноярский",
  "м.р. Нефтегорский",
  "м.р. Пестравский",
  "м.р. Похвистневский",
  "м.р. Приволжский",
  "м.р. Сергиевский",
  "м.р. Ставропольский",
  "м.р. Сызранский",
  "м.р. Хворостянский",
  "м.р. Челно-Вершинский",
  "м.р. Шенталинский",
  "м.р. Шигонский",
];

const optionsCity = City.map((el) => {
  return <option key={el}>{el}</option>;
});
const optionsCity2 = City2.map((el) => {
  return <option key={el}>{el}</option>;
});

export const UserCity = ({ userTest1, setUserTest1 }) => {
  const [citySelect, setCitySelect] = useState("null");
  const [citySelect2, setCitySelect2] = useState("null");

  //   useEffect(() => {
  //     if (citySelect === null && citySelect2 === null) {
  //       document.querySelector(".test1__next").setAttribute("disabled", "");
  //     } else {
  //       document.querySelector(".test1__next").removeAttribute("disabled");
  //     }
  //   }, [citySelect, citySelect2]);

  return (
    <div className="test__wrapper">
      <h2 className="question__title">
        Вопрос 3. В каком муниципальном образовании Вы находитесь? <br />
        <center>(надо выбрать один из представленных вариантов)</center>
      </h2>
      <br />
      <br />

      <h3>Городской округ:</h3>
      <select
        className="select"
        onChange={(event) => {
          setCitySelect(event.target.value);
          setCitySelect2(null);
          setUserTest1({
            ...userTest1,
            city: event.target.value,
          });
        }}
        value={citySelect === null ? "Сделайте выбор" : citySelect}
      >
        {optionsCity}
      </select>
      <h4>или</h4>
      <h3>Муниципальный район:</h3>
      <select
        className="select"
        onChange={(event) => {
          setCitySelect2(event.target.value);
          setCitySelect(null);
          setUserTest1({
            ...userTest1,
            city: event.target.value,
          });
        }}
        value={citySelect2 === null ? "Сделайте выбор" : citySelect2}
      >
        {optionsCity2}
      </select>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};
