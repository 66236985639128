import React from 'react';
import './header.css';

export const Header = () => {
    return (
        <div className="header">
            <h1>
                "Программа сокращения потребления психоактивных веществ:
                Голосовые модули"
            </h1>
        </div>
    );
};
