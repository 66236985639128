import React, { useRef, useState } from 'react';
import video2 from '../../../audio/alkogolAll.mp3';
import video3 from '../../../audio/narkotiki.mp3';
import { getResultTest1 } from '../../../utils/getResultTest1';
import Modal from 'react-modal';
import './modal.css';
Modal.setAppElement('#root');

export const VideoPlayer = ({ userTest1, setStage }) => {
    const videoRef = useRef(null);
    const [modalIsOpen, setModalIsOpen] = useState(true);

    const closeModal = () => {
        setModalIsOpen(false);
    };
    const variant = getResultTest1(userTest1);
    const modalContent = (
        <div>
            <h2>Уважаемый пользователь !</h2>
            {variant === 'two' ? (
                <p>
                    Просим Вас прослушать два голосовых модуля. Нажмите закрыть.
                </p>
            ) : (
                <p>
                    Просим Вас прослушать прослушать голосовой модуль. Нажмите
                    закрыть.
                </p>
            )}
            <button className="modal__button" onClick={closeModal}>
                Закрыть
            </button>
        </div>
    );

    return (
        <>
            {
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    className="Modal"
                    overlayClassName="Overlay"
                >
                    {modalContent}
                </Modal>
            }
            {variant === 'two' && (
                <div>
                    <p>Голосовые модули. Для прослушивания нажмите Play.</p>
                    <video controls className="video_block" ref={videoRef}>
                        <source src={video2} type="audio/mpeg" />
                    </video>
                    <hr />
                    <video controls className="video_block" ref={videoRef}>
                        <source src={video3} type="audio/mpeg" />
                    </video>
                    <hr />
                    <button
                        className="content__button"
                        onClick={() => setStage(4)}
                    >
                        Завершить прослушивание
                    </button>
                </div>
            )}
            {variant !== 'two' && (
                <div>
                    <p>Голосовой модуль. Для прослушивания нажмите Play.</p>
                    <video controls className="video_block" ref={videoRef}>
                        <source src={variant} type="audio/mpeg" />
                    </video>
                    <hr />
                    <button
                        className="content__button"
                        onClick={() => setStage(4)}
                    >
                        Завершить прослушивание
                    </button>
                </div>
            )}
        </>
    );
};
