import React from 'react';

export const GoodFinish = ({ setStage, setUserTest1 }) => {
    setTimeout(() => {
        setStage(1);
        setUserTest1({ sdt: '', alt: '', ast: '', ggt: '' });
    }, 4000);
    return (
        <div className="good_finish">
            <p>
                Полученные в ходе Вашего опроса ответы не позволяют сделать
                вывод о рискованном потреблении психоактивного вещества.
                <br></br>
                <br></br>
                Мы благодарим Вас за участие в опросе и напоминаем, что
                потребления любых психоактивных веществ может привести к
                зависимости от него, что в свою очередь приводит к сокращению
                продолжительности жизни. <br></br>
                <br></br>
                Спасибо, и будьте здоровы!
            </p>
        </div>
    );
};
