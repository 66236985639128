import { useState } from 'react';
import React from 'react';
import './question/question.css';

export const DataUser = ({ userTest1, setUserTest1 }) => {
    const [checked, setChecked] = useState(null);
    const [inputValue, setInputValue] = useState('');
    return (
        <div className="dataUser">
            <span>1. Укажите Ваш пол :</span>
            <br />
            <br />
            <label className="label_question">
                <input
                    className="input_checkbox"
                    type="checkbox"
                    checked={checked === 'Мужской'}
                    onChange={() => {
                        setChecked('Мужской');
                        setUserTest1({ ...userTest1, gender: 'Мужской' });
                    }}
                ></input>
                Мужской
            </label>
            <br />
            <br />
            <label className="label_question">
                <input
                    className="input_checkbox"
                    type="checkbox"
                    checked={checked === 'Женский'}
                    onChange={() => {
                        setChecked('Женский');
                        setUserTest1({ ...userTest1, gender: 'Женский' });
                    }}
                ></input>
                Женский
            </label>
            <br />
            <br />
            <span>2. Укажите количество полных лет</span>
            <br />
            <br />
            <label className="label_question">
                <input
                    className="datauser_age"
                    type="number"
                    value={inputValue}
                    onChange={(event) => {
                        setInputValue(event.target.value);
                        setUserTest1({ ...userTest1, age: event.target.value });
                    }}
                ></input>
                Возраст
            </label>
        </div>
    );
};
