import React from 'react';
import './footer.css';

export const Footer = ({ onClick }) => {
    return (
        <div className="footer">
            <h5>СОКНД, Самара 2024</h5>
            <button className="content__button" onClick={onClick}>
                Вернуться в начало
            </button>
        </div>
    );
};
